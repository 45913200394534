import React from 'react'


const PartnerCard = ({ logo, name, description, linkName, linkURL }) => {
    return (
        <div className='flex flex-col xl:flex-row h-full rounded-lg shadow-xl p-5 bg-shell dark:bg-neutral-800'>

            <div className='basis-2/5 w-full h-auto flex flex-col justify-center items-center p-7 ws:p-10 xl:p-0'>
                <img className='h-auto w-full object-cover' src={logo}/>
            </div>

            <div className='basis-3/5 w-full h-auto flex flex-col justify-end xl:justify-center pl-6 py-5'>
                <h3 className='font-bold text-2xl ws:text-5xl mb-2'>{name}</h3>
                <p className='ws:text-2xl'>{description}</p>
                <a className='block mt-3 text-lg ws:text-3xl underline' href={linkURL} target="_blank">{linkName}</a>
            </div>

        </div>
    )
}

export default PartnerCard