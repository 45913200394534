import * as React from 'react'
import Layout from '../components/Layout'
import PartnerCard from '../components/PartnerCard'
import nasa_worm from '../images/company-logos/nasa-worm.svg'
import northrop_grumman from '../images/northrop_grumman.png'
import sydereal from '../images/sydereal.png'
import spark_systems from '../images/sparx_systems.png'
import tukom from '../images/tukom.png'
import innoslate from '../images/innoslate.png'
import jundroo from '../images/jundroo.png'
import patchion from '../images/patchion.png'


const lorem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."

export default function PartnersPage() {
    return (
        <Layout title='Partners | Conex Research'>
            <section className='w-screen h-fit px-8 xl:px-32 my-20 flex flex-col'>

                <h1 className='text-5xl sm:text-7xl ws:text-9xl font-bold'>Our Partners</h1>
                <h2 className='mb-20 text-xl sm:text-3xl ws:text-5xl'>Driving our missions forward</h2>

                <div className='h-full flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-5 gap-y-20'>
                    <PartnerCard logo={nasa_worm} name='NASA' description={'Needing no introduction, the National Aeronautics and Space Administration is an independent US governmental agency reponsible for the country\'s space program and research. NASA have been instrumental in progressing space exploration, through their flaship Apollo, Space Shuttle, and ISS programs to name a few.'} linkName='nasa.gov' linkURL='https://www.nasa.gov/' />
                    <PartnerCard logo={northrop_grumman} name='Northrop Grumman' description={'Northrop Grumman are a leading aerospace company, with a wide variety of projects and expertise reaching across every sector of space, from private industry to government missions. More recently, they were a key partner with NASA in ensuring the success of the James Webb Space Telescope.'} linkName='northropgrumman.com' linkURL='https://www.northropgrumman.com' />
                    <PartnerCard logo={sydereal} name='Sydereal Space' description={'Sydereal is a leading provider of operations technology. They assist in enabling organisations to deploy assets into space through consulting on how to successfully excecute spaceflight operations.'} linkName='sydereal.space' linkURL='https://sydereal.space/' />
                    <PartnerCard logo={spark_systems} name='Sparx Systems' description={'Sparx Systems are the creators of Enterprise Architect, pioneering systems engineering software designed to help individuals, groups and large organizations model and manage complex information'} linkName='sparxsystems.com' linkURL='https://sparxsystems.com/' />
                    <PartnerCard logo={tukom} name='Tukom' description={'Tukom works hand in hand with expert international partners to offer deep market knowledge on space communications, telemetry, and more. Their team offer industry leading commitment and passion to their client projects, helping them achieve their strategic goals in space.'} linkName='tukom.de' linkURL='https://www.tukom.de' />
                    <PartnerCard logo={innoslate} name='Innoslate' description={'Innoslate offers a single solution to support the entire systems engineering discipline. Their unique suite of tools allows all our team to collaborate in model based systems engineering, as well as requirements engineering and analysis.'} linkName='innoslate.com' linkURL='https://www.innoslate.com/' />
                    <PartnerCard logo={jundroo} name='jundroo' description={'Jundroo is a games development studio behind excellent simulation games including SimplePlanes, and our personal favourite SimpleRockets! This allows us to produce fun and engaging models to share with the public.'} linkName='jundroo.com' linkURL='https://jundroo.com/' />
                    <PartnerCard logo={patchion} name='Patchion' description={'Patchion are our partners in manufacturing the amazing Conex and Arcanum embroidered patches. These have travelled the world with us, and stand as a unique and memorable gift to offer those we meet.'} linkName='embroidered-patches.co.uk' linkURL='https://www.embroidered-patches.co.uk/' />
                    
                </div>

                <div className='w-fit h-fit flex flex-col justify-center items-center self-center mt-20 px-10 py-7 rounded-lg shadow-xl bg-shell dark:bg-neutral-800'>
                    <h3 className='font-bold text-3xl text-center mb-3'>Interested in working with us?</h3>
                    <p className='text-xl sm:text-xl text-center my-5'>
                        We'd love to talk!<br/>
                    </p>
                    <p className='text-lg sm:text-xl text-center'>
                        Let us know at <a href='mailto:partners@conexresearch.com'>partners@conexresearch.com</a> how we can work together.
                    </p>
                </div>

            </section>
        </Layout>
    )
}
